import * as React from "react";
import { useGakka } from "../../../hooks/useGakka/useGakka";

const LeftBar = ({ children }: any) => {
  const gakka = useGakka();
  return (
    <div
      className={`tw-border-l-8 tw-border-${gakka.color} md:tw-border-l-px16`}
    >
      {children}
    </div>
  );
};

export default LeftBar;
