import * as React from "react";

const H2 = ({ title }: any) => {
  return (
    <h2 className="tw-pb-2 tw-border-b-2 tw-border-baseLightGray tw-text-htmlH2 tw-font-bold md:tw-pb-6 md:tw-border-b-4 md:tw-text-htmlH2pc">
      {title}
    </h2>
  );
};

export default H2;
